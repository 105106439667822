
    @apply --marketplacePaperBackground;
    @apply --marketplaceButtonFontStyles;
    
    display: block;
    width: 100%;
    margin: 0;
    padding: 17px 0 17px 0;
    @media (--viewportMedium) {
      padding: 17px 0 17px 0;
    }

    
    background-color: var(--marketplaceColorSecond);
    color: var(--matterColorLight);

    
    text-align: center;
    text-decoration: none;

    
    transition: all var(--transitionStyleButton);
    cursor: pointer;

    &:hover,
    &:focus {
      outline: none;
      background-color: var(--marketplaceColorDark);
      text-decoration: none;
      box-shadow: var(--boxShadowButton);
    }

    &:disabled {
      background-color: var(--matterColorNegative);
      color: var(--matterColorLight);
      cursor: not-allowed;
      box-shadow: none;
    }

    @media (--viewportMedium) {
      min-height: 61px;
    }
  